.container {
    text-align: center;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

 .fondo {
  background-image: linear-gradient(70deg, #293241, #3d5a80);
 }

.fondo_two {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  /* background-image: url('../../Assets/fondo_two.svg'); */
  /* background-image: linear-gradient(70deg, #293241, #3d5a80); */
  background-size: cover;
  z-index: -2;
}

.contain_mano {
  display: block; 
  margin-top: 2rem; /* Ajusta según lo que necesites */
  text-align: right; /* Centra la imagen */
  position: absolute; 
  top: 5rem;
  right: 5rem;
}

.contain_mano_img {
  width: 400px; 
}

.fondo_dinero {
  position: absolute;
  top: 7rem;
  right: 9rem; 
  width: 350px; 
  height: 220px;
  background-image: url('../../Assets/dinero.png');
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  z-index: -1; /* Asegura que esté sobre fondo_two */
}


  .title {
    text-align: left;
    padding-left: 9rem;
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    font-size: 5.5rem;
    margin-bottom: 4rem;
    margin-top: 2rem;
    padding-top: 4rem;
  }

  .textContainer {
    color: #fff;
    position: relative;
    z-index: 1;
    padding-top: 2rem;
}
  .text {
    font-size: 1.6rem; 
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: left;
    padding-left: 9rem;
    padding-right: 9rem;
    padding-bottom: 7rem;
  }

.title_two {
  color: #383838!important;
  text-align: center;
  justify-content: center;
  width: 100%;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 2rem;
}

.text_two {
  color: #383838!important;
  font-size: 1.3rem; 
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  text-align: center;
  margin-left: 5rem;
  margin-right: 5rem;
  margin-bottom: 4rem;
}

.title_three {
  /* color: #fff; */
  color: #383838!important;
  text-align: left;
  justify-content: center;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-size: 3.5rem;
  padding-left: 9rem;
  margin-bottom: 2rem;
  margin-top: 0rem;
  padding-top: 0px;
}

.text_three {
  color: #383838!important;
  width: 50%;
  font-size: 1.4rem; 
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  text-align: left;
  padding-left: 9rem;
  padding-right: 9rem;
  margin-bottom: 4rem;
}


@media (min-width: 600px) and (max-width: 1100px) {
  .title_three{
    /* padding-top: 0px; */
    /* font-size: 2.7rem; */
    padding-left: 5rem;
    /* text-align: center; */
  }
  .text_three{
    padding-left: 5rem;
  }
  .contain_mano_img {
    width: 300px !important; 
    /* margin-left: 2rem; */
  }
  .contain_mano{
    top: 40%;
    right: 1rem;
    /* margin-left: 2rem;  */
  }
  .fondo_dinero {
    display: none;
  }
}


  @media (max-width: 575px) {
    .contain_mano_img {
      width: 300px !important; 
    }
    .contain_mano{
      display: block; /* O podés usar 'flex' si querés otro tipo de control */
      text-align: center;
      position: relative;
      z-index: 0;
      margin-bottom: 2rem;
      right: 0;
      top: 0px;
    }
    .fondo_dinero{
      display: none;
    }
    .text_three, .text, .title, .text_two, .title_three, .title_two {
      padding-left: 0px;
      margin-left: 0px;
    }
    .title{
      font-size: 3.5rem;
      padding-left: 1rem;
      margin-bottom: 0px;
      margin-top: 8px;
      padding-top: 25px;
    }
    .text{
      padding-left: 1rem;
      padding-right: 0;
      width: auto;
    }
    .title_two{
      font-size: 1.5rem;
    }
    .text_two{
      text-align: center;
      margin-right: 0;
      margin-bottom: 0px;
    }
    .title_three{
      padding-top: 0px;
      font-size: 2.7rem;
      /* padding-left: 1rem; */
      text-align: center;
    }
    .text_three{
      text-align: center;
      margin: 0;
      padding: 0;
      width: auto;
      margin-bottom: 0px;
    }
    .text, .text_two, .text_three{
      margin-left: .5rem;
      margin-right: .5rem;
    }
    .textContainer {
      padding-top: 0px;
  }
}
