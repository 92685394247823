body {
  background-size: cover;
  background-attachment: fixed;
  background-position: left;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Poppins',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e6e6e6;
}

body::before {
  content: '';
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); 
  opacity: 0; /* Inicialmente invisible */
  visibility: hidden; /* Inicialmente no visible */
  z-index: 2; 
  transition: opacity 0.5s ease, visibility 0.5s ease;
}

body.menu_active::before {
  opacity: 1; /* Ahora es visible */
  visibility: visible; 
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css?family=Roboto|Open+Sans|Georama|Montserrat|Lato|Raleway|Poppins|Assistant|Comfortaa|Blinker|Red+Hat+Display|Outfit|Jost|Ubuntu|Playfair+Display|Source+Sans+Pro|Merriweather&display=swap');


