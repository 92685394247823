.container {
    text-align: center;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

/* .fondo {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-image: url('../../Assets/bannerHome.47f5deb32febc5c93be0.svg');
    background-position: center;
    background-size: cover;
    z-index: -2;
} */
.fondo{
  background-image: linear-gradient(70deg, #293241, #3d5a80);
}

.textContainer {
    color: #fff;
    position: relative;
    z-index: 1;
    /* padding: .1rem; */
}

.title {
    text-align: center;
    /* padding-left: 9rem; */
    justify-content: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    font-size: 5rem;
    padding-bottom: 2rem;
    margin-top: 0px;
    padding-top: 6rem;
  }

.container {
    text-align: center;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .title_two {
    color: #383838!important;
    text-align: center;
    justify-content: center;
    width: 100%;
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .text_two {
    color: #383838!important;
    font-size: 1.3rem; 
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: left;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 4rem;
  }

.subtitle_two{
    color: #383838!important;
    text-align: left;
    justify-content: center;
    /* width: 100%; */
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    font-size: 1.5rem;
    margin-left: 5rem;
    padding-bottom: 0px!important;
    margin-bottom: 0px;
}

.text_two_bottom {
    color: #383838!important;
    font-size: 1.3rem; 
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: left;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-bottom: 10px;
  }

.text_two_margin{
    color: #383838!important;
    font-size: 1.3rem; 
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    text-align: left;
    margin-left: 5rem;
    margin-right: 5rem;
    margin-top: 0px;
    margin-bottom: 10px;
}


  @media (max-width: 575px) {

    .text_three, .text, .title, .text_two, .title_two {
      padding-left: 0px;
      margin-left: 0px;
    }
    .title{
      font-size: 2.8rem;
      margin-bottom: 0px;
      margin-top: 8px;
      padding-top: 25px;
    }
    .text{
      padding-left: 1rem;
      padding-right: 0;
      width: auto;
    }
    .title_two{
      font-size: 1.5rem;
    }
    .text_two, .text_two_margin, .subtitle_two{
      text-align: left;
      margin-right: 5px !important;
      margin-left: 15px !important;
    }
    .text, .text_two, .text_two_margin{
      margin-left: .5rem;
      margin-right: .5rem;
    }
    .textContainer {
      padding-top: 0px;
  }
}
