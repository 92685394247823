.card {
    padding: 1rem;
    background-color: #fff;
    max-width: 270px;
    border-radius: 20px;
    box-shadow: 10px 10px #323232;
    border: 3px solid #323232;
    height: auto;
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
  }
  
  .title {
    display: flex;
    align-items: center;
  }
  
  .title span {
    position: relative;
    padding: 0.5rem;
    background-color: #024f97;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 9999px;
  }
  
  .title span svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ffffff;
    height: 1rem;
  }
  
  .title_text {
    margin-left: 0.5rem;
    color: #374151;
    font-size: 14px;
    text-transform: uppercase;
    font-weight:500;
  }
  
  .percent {
    margin-left: 0.5rem;
    color: #024f97;
    font-weight: 600;
    display: flex;
  }
  
  .data {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .data p {
    margin-top: 1rem;
    margin-bottom: 1rem;
    color: #1F2937;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-weight: 700;
    text-align: left;
  }
  
  .data .range {
    position: relative;
    background-color: #E5E7EB;
    width: 100%;
    height: 0.5rem;
    border-radius: 0.25rem;
  }
  
  .data .range .fill {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #024f97;
    width: 76%;
    height: 100%;
    border-radius: 0.25rem;
  }

.editMode_input{
  border-radius: 5px;
  height: 1.7rem;
}

.editMode_cancel{
  text-decoration: none;
  background-color: #0b315ce8;
  cursor: pointer;
  color: white;
  margin-left: 2rem;
  margin-right: 2rem;
  border-radius: 10px;
  margin-top: 1rem;
  width: 5rem;
  height: 2rem;
}

.editMode_save{
  text-decoration: none;
  cursor: pointer;
  background-color: #0b315ce8;
  color: white;
  margin-top: 1rem;
  margin-right: 2rem;
  border-radius: 10px;
  width: 5rem;
  height: 2rem;
}

.data input{
  border-radius: 4px;
  width: 10rem;
  height: 1.8rem;
  text-transform: uppercase;
  font-weight: bold;
  color: #1d1d1d;
  font-size: 18px;
}