.switch_container {
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
  padding-top: 1rem;
}

.theme_switch {
  direction: rtl;
  display: flex;
  margin-right: 2.5rem;
  padding-top: 1.5rem;
}

.theme_checkbox {
  display: none;
}

.theme_checkbox + label {
  font-size: 2rem;
  height: 1em;
  width: 2.5em;
  border-radius: 0.25em;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  background-color: #cecece;
  position: relative;
}

.theme_checkbox:checked + label {
  background-color: #3a3a3a;
}

.theme_checkbox + label:active {
  transform: scale(0.85);
  transition: transform 0.2s;
}

.theme_checkbox + label div {
  width: 0.8em;
  height: 0.8em;
  border-radius: inherit;
  position: absolute;
  top: 0.1em;
  left: 0.1em;
  z-index: 10;
  transition: 0.5s cubic-bezier(1, 0.33, 0.11, 1.34);
  background-color: #f2f2f2;
}

.theme_checkbox:checked + label div {
  /* left: calc(2.5em - .8em - .1em); */
  left: 1.6em;
  background-color: #212121;
}

.theme_checkbox + label span {
  display: flex;
}

.theme_checkbox + label svg {
  display: inline-block;
  height: 1em;
  width: 1em;
  padding: 0.15em;
  box-sizing: border-box;
}
.theme_checkbox + label span:first-of-type {
  color: #3a3a3a;
}
.theme_checkbox + label span:last-of-type {
  color: #cecece;
}
